<template>
    <div>
        <h4>Choose all that apply</h4>
        <v-checkbox
            :label="text"
            :input-value="metaData[key]"
            :value="true"
            hide-details
            @change="handleChange(key, $event)"
            v-for="({ key, text }, index) in items"
            :key="index"
        ></v-checkbox>
    </div>
</template>
<script>
export default {
    name: "mobility-rental-history",
    components: {},
    props: {
        applicant: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            metaData: Object.assign({}, this.applicant.meta.mobility),
            items: [
                {
                    key: "disabled_not_mobility",
                    text: this.$t("applications.disabled-not-mobility"),
                },
                {
                    key: "disabled_mobility",
                    text: this.$t("applications.disabled-mobility"),
                },
                {
                    key: "current_resident",
                    text: this.$t("applications.current-resident"),
                },
            ],
        };
    },
    watch: {
        applicant: {
            immediate: true,
            handler(applicant) {
                this.metaData = Object.assign({}, applicant.meta.mobility);
            },
        },
    },
    methods: {
        handleChange(key, value) {
            // Avoid setting null
            this.metaData[key] = value ? true : false;
            this.$emit("update", {
                applicant: {
                    meta: Object.assign(this.applicant.meta, {
                        mobility: this.metaData,
                    }),
                },
            });
        },
    },
    mounted() {
        // Complete is just displaying the options
        this.$emit("update", {
            complete: true,
        });
    },
};
</script>

<style scoped>
</style>