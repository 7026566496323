var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("h4", [_vm._v("Choose all that apply")]),
      _vm._l(_vm.items, function(ref, index) {
        var key = ref.key
        var text = ref.text
        return _c("v-checkbox", {
          key: index,
          attrs: {
            label: text,
            "input-value": _vm.metaData[key],
            value: true,
            "hide-details": ""
          },
          on: {
            change: function($event) {
              return _vm.handleChange(key, $event)
            }
          }
        })
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }